<template>
  <li :class="{ 'is-active': isDropdownActive }">
    <component
      :is="componentIs"
      :to="item.to"
      :href="item.href"
      :target="item.target"
      exact-active-class="is-active"
      :class="{
        'has-icon': !!item.icon,
        'has-dropdown-icon': hasDropdown,
        'p-5': true,
        'is-flex': true,
        'has-text-weight-semibold':true,
        'has-text-info': (item.label === 'Back to Dashboard' )?true:false,
        'is-active': (isReconcilePage(item.to) ) ? true :false
      }"
      @click="menuClick"
    >
      <span class="material-symbols-outlined px-4"> {{ item.icon }} </span>
      <span
        v-if="item.label"
        :class="{
          'menu-item-label': !!item.icon,
          'is-align-self-center': true,
        }"
      >
        {{ item.label }}
      </span>
      <div
        v-if="hasDropdown"
        class="dropdown-icon"
      >
        <b-icon
          :icon="dropdownIcon"
          custom-size="default"
        />
      </div>
    </component>
    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      is-submenu-list
    />
  </li>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: () => import('@/components/AsideMenuList.vue')
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  emits: ['menu-click'],
  data () {
    return {
      isDropdownActive: false
    }
  },
  computed: {
    componentIs () {
      return this.item.to ? 'router-link' : 'a'
    },
    hasDropdown () {
      return !!this.item.menu
    },
    dropdownIcon () {
      return this.isDropdownActive ? 'minus' : 'plus'
    }
  },
  methods: {
    isReconcilePage (segment) {
      return this.$route.path.includes('reconcile') && segment.includes('supplier-matching')
    },
    menuClick () {
      this.$emit('menu-click', this.item)

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive
      }
    }
  }
})
</script>
