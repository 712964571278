import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Dashboard'
    },
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },

  {
    meta: {
      title: 'Statement Matching'
    },
    path: '/supplier-matching',
    name: 'Supplier matching',
    component: () => import('@/views/StatementMatching.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'suppliers'
    },
    path: '/settings/suppliers',
    name: 'suppliers',
    component: () => import('@/views/settings/Supplier.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'AutoReconcile'
    },
    path: '/reconcile/:partnerId/:periodDate',
    name: 'reconcile.edit',
    component: () => import('@/views/ReconcileTaskDetail.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'Statement Summary'
    },
    path: '/statement-summary',
    name: 'Statement.Summary',
    component: () => import('@/views/StatementSummary.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import('@/views/ClientFormView.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import('@/views/ClientFormView.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    },
    props: true
  },
  {
    path: '/auth-pages',
    component: () => import('@/views/FullPageView.vue'),
    children: [
      {
        meta: {
          title: 'Login'
        },
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth-pages/LoginView.vue')
      }
    ]
  },

  {
    path: '/auth-pages',
    component: () => import('@/views/FullPageView.vue'),
    children: [
      {
        meta: {
          title: 'Sign up'
        },
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/auth-pages/SignupView.vue')
      }
    ]
  },
  {
    path: '/auth-pages',
    component: () => import('@/views/FullPageView.vue'),
    children: [
      {
        meta: {
          title: 'Forgot Password'
        },
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/auth-pages/ForgotpasswordView.vue')
      }
    ]
  },
  {
    path: '/auth-pages',
    component: () => import('@/views/FullPageView.vue'),
    children: [
      {
        meta: {
          title: 'Reset Password'
        },
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/auth-pages/ResetPasswordView.vue')
      }
    ]
  },
  //* settings pages*//
  {
    meta: {
      title: 'Account Deatils'
    },
    path: '/settings/account-details',
    name: 'Account Details',
    component: () => import('@/views/AccountDetails.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'My Team'
    },
    path: '/settings/my-team',
    name: 'myteam',
    component: () => import('@/views/settings/MyTeam.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'Suppliers'
    },
    path: '/settings/suppliers',
    name: 'Suppliers',
    component: () => import('@/views/settings/Supplier.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'Categories'
    },
    path: '/settings/Categories',
    name: 'categories',
    component: () => import('@/views/settings/Categories.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  },
  {
    meta: {
      title: 'Reconciliation Rules'
    },
    path: '/settings/reconciliation-rules',
    name: 'Reconciliation Rules',
    component: () => import('@/views/settings/ReconcileRules.vue'),
    beforeEnter: (to, from, next) => {
      guard(to, from, next)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
const guard = function (to, from, next) {
  const token = localStorage.getItem('bearerToken')
  if (token && !isTokenExpired(token)) {
    next()
    return
  }
  router.push('/login')
}
export default router

export function useRouter () {
  return router
}

function isTokenExpired (token) {
  try {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  } catch (error) {
    return true
  }
}
