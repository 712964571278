
import AuthService from './auth.service.js'
export default function setupAxios (axios) {
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('bearerToken')
    if (!config.url.includes('api=login')) {
      if (token) {
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`
      }
    }
    return config
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      const originalRequest = error.config
      // Check if the response status is 404 and it is not a retry
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        // Attempt to refresh the token
        try {
          const user = JSON.parse(localStorage.getItem('UserInfo'))
          AuthService.login(user).then(
            user => {
              if (user.success) {
                const newToken = user.data.token
                localStorage.setItem('bearerToken', JSON.stringify(newToken))
                // Update the Authorization header with the new token
                axios.defaults.headers.common.Authorization = `Bearer ${newToken}`
                originalRequest.headers.Authorization = `Bearer ${newToken}`
                // Resend the original request
                return axios(originalRequest)
              }
            },
            error => {
              return Promise.reject(error)
            }
          )
        } catch (refreshError) {
          // If token refresh fails, reject the original error
          return Promise.reject(refreshError)
        }
      }
      return Promise.reject(error)
    }
  )
}
