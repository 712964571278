/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Vue. Main component */
import App from './App.vue'
import axios from 'axios'
import setupAxios from './services/setupAxios'

/* Default title tag */
const defaultDocumentTitle = 'AutoReconcile'

router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  // store.dispatch("asideDesktopOnlyToggle", false);

  if (to.meta && to.meta.title) {
    document.title = `${defaultDocumentTitle} - ${to.meta.title}`
  } else {
    document.title = defaultDocumentTitle
  }
})

setupAxios(axios)

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
