<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main "
    class="navbar is-fixed-top no-print"
  >
    <div class="navbar-brand">
      <router-link
        to="/"
        class="navbar-item"
        exact-active-class="is-active"
      >
        <img
          :src="isDark? darkAvatarSrc : lightAvatarSrc"
          class="navbar-logo "
          alt="Automated Statement Matching - AutoReconcile"
        >
      </router-link>
      <!-- <a
        class="navbar-item is-hidden-touch  is-desktop-icon-only"
        @click.prevent="asideDesktopMenuOnlyToggle"
      >
        <b-icon :icon="asideDesktopMenuIcon" />
      </a> -->
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="asideToggleMobile"
      >
        <b-icon :icon="asideMobileIcon" />
      </a>
      <a
        class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only"
        @click.prevent="asideDesktopOnlyToggle"
      >
        <b-icon icon="menu" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuToggle"
      >
        <b-icon
          :icon="menuToggleIcon"
          custom-size="default"
        />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuActive }"
    >
      <div class="navbar-end header">
        <div class="is-flex is-justify-content-center is-align-items-center height_full">
          <p
            class="mx-3 mt-1 is-clickable"
            @click="toggleDark(!isDark)"
          >
            Dark Mode
          </p>
          <b-switch
            id="dark-mode-switch"
            v-model="isDark"
            class="mt-1 custom-switch"
            label-left="Off"
            type="is-info"
            label=""
            @input="toggleDark"
          >
            <span class="switch-text">{{ isDark ? '' : '' }}</span>
          </b-switch>
        </div>
        <nav-bar-menu class="has-divider has-user-avatar">
          <img
            src="./../assets/avatar.svg"
            class="px-3"
            alt="avatar_img"
          >
          <div class="is-user-name">
            <span>{{ userName }}</span>
          </div>
          <div
            slot="dropdown"
            class="navbar-dropdown"
          >
            <router-link
              to="/settings/account-details"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <span>Account Details</span>
            </router-link>
            <a
              v-if="isAdmin"
              class="navbar-item"
            >
              <span>Admin Dashboard</span>
            </a>
            <router-link
              to="/settings/account-details"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <span>Settings</span>
            </router-link>
            <hr class="navbar-divider">
            <a
              class="navbar-item"
              @click.prevent="logout"
            >
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu.vue'
import AuthService from '../services/auth.service.js'

import darkAvatarSrc from './../assets/logo-dark.svg'
import lightAvatarSrc from './../assets/logo.svg'
import { useDark, useToggle } from '@vueuse/core'
export default defineComponent({
  name: 'NavBar',
  components: {
    NavBarMenu
  },
  data () {
    return {
      isMenuActive: false,
      isDark: useDark(),
      darkAvatarSrc,
      lightAvatarSrc,
      toggleDark: useToggle(useDark())
    }
  },
  computed: {
    asideDesktopMenuIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    asideMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon () {
      return this.isMenuActive ? 'close' : 'dots-vertical'
    },
    ...mapState(['isAsideMobileExpanded', 'isNavBarVisible', 'userName', 'isAdmin'])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuActive = false
    })
  },
  methods: {
    asideToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    asideDesktopOnlyToggle () {
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    menuToggle () {
      this.isMenuActive = !this.isMenuActive
    },
    asideDesktopMenuOnlyToggle () {
      this.$store.commit('asideMobileStateToggle')
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    logout () {
      AuthService.logout()
      setTimeout(() => {
        this.$router.push('/login')
        this.$buefy.snackbar.open({
          message: 'Logging off for now. Take care!',
          queue: false
        })
      }, 50)
    }
  }
})
</script>
