var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{class:{ 'is-active': _vm.isDropdownActive }},[_c(_vm.componentIs,{tag:"component",class:{
      'has-icon': !!_vm.item.icon,
      'has-dropdown-icon': _vm.hasDropdown,
      'p-5': true,
      'is-flex': true,
      'has-text-weight-semibold':true,
      'has-text-info': (_vm.item.label === 'Back to Dashboard' )?true:false,
      'is-active': (_vm.isReconcilePage(_vm.item.to) ) ? true :false
    },attrs:{"to":_vm.item.to,"href":_vm.item.href,"target":_vm.item.target,"exact-active-class":"is-active"},on:{"click":_vm.menuClick}},[_c('span',{staticClass:"material-symbols-outlined px-4"},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]),(_vm.item.label)?_c('span',{class:{
        'menu-item-label': !!_vm.item.icon,
        'is-align-self-center': true,
      }},[_vm._v(" "+_vm._s(_vm.item.label)+" ")]):_vm._e(),(_vm.hasDropdown)?_c('div',{staticClass:"dropdown-icon"},[_c('b-icon',{attrs:{"icon":_vm.dropdownIcon,"custom-size":"default"}})],1):_vm._e()]),(_vm.hasDropdown)?_c('aside-menu-list',{attrs:{"menu":_vm.item.menu,"is-submenu-list":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }