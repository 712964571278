import axios from 'axios'
import qs from 'qs'

const API_URL = `${process.env.VUE_APP_API_URL}`

class AuthService {
  async login (user) {
    const data = {
      username: user.username,
      hash: user.hash
    }
    const config = {
      method: 'post',
      url: `${API_URL}login`,
      data: qs.stringify(data)
    }
    return axios
      .request(config)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  }

  logout () {
    localStorage.removeItem('bearerToken')
    localStorage.removeItem('userName')
    localStorage.removeItem('isAdmin')
  }

  async register (formData) {
    const config = {
      method: 'post',
      url: API_URL + 'register&action=newUserRegister',
      crossDomain: true,
      data: formData
    }
    try {
      const response = await axios
        .request(config)
      return response.data
    } catch (error) {
      return error
    }
  }

  async forgotPassword (formData) {
    const config = {
      method: 'post',
      url: API_URL + 'resetPassword',
      crossDomain: true,
      data: formData
    }
    try {
      const response = await axios
        .request(config)
      return response.data
    } catch (error) {
      return error
    }
  }
}

export default new AuthService()
