<template>
  <aside
    v-show="isAsideVisible"
    class="aside is-placed-left"
  >
    <div class="nev_top_sec p-3">
      <a
        v-if="navState == 'settings'"
        class="back-dashboard-link"
        @click="$emit('changeNavView')"
      >
        <span class="material-symbols-outlined px-4 small-icon">arrow_back</span>
        <span class="bottom-menu-label has-text-weight-semibold"><b>Back to Dashboard</b></span>
      </a>
      <a
        class="is-hidden-touch is-desktop-icon-only small-icon"
        :class="{ 'align-right': navState !== 'settings' }"
        @click.prevent="asideDesktopMenuOnlyToggle"
      >
        <b-icon :icon="asideDesktopMenuIcon" />
      </a>
    </div>
    <template v-for="(menuGroup, index) in menu">
      <p
        v-if="typeof menuGroup === 'string'"
        :key="`label-${index}`"
        class="menu-label"
      >
        {{ menuGroup }}
      </p>
      <aside-menu-list
        v-else
        :key="`menu-${index}`"
        :menu="menuGroup"
        @menu-click="menuClick"
      />
    </template>
    <div class="nev_bottom_sec">
      <a
        v-if="navState == 'menu'"
        @click="$emit('changeNavView')"
      >
        <span class="material-symbols-outlined px-4"> settings </span>
        <span class="bottom-menu-label"> Settings </span>
      </a>
      <a>
        <span class="material-symbols-outlined px-4"> 3p </span>
        <span class=" bottom-menu-label"> Support </span>
      </a>
    </div>
  </aside>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import '@fortawesome/fontawesome-free/css/all.css'
import AsideMenuList from '@/components/AsideMenuList.vue'
export default defineComponent({
  name: 'AsideMenu',
  components: { AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    navState: {
      type: String,
      default: 'menu'
    }
  },
  computed: {
    asideDesktopMenuIcon () {
      return this.isAsideMobileExpanded ? 'chevron-double-right ' : 'chevron-double-left'
    },
    ...mapState(['isAsideVisible', 'isAsideMobileExpanded', 'isNavBarVisible', 'userName', 'isAdmin'])
  },
  methods: {
    asideMobileIcon () {
      return this.isAsideMobileExpanded ? 'chevron-double-right' : 'chevron-double-left'
    },
    asideDesktopMenuOnlyToggle () {
      this.$store.commit('asideMobileStateToggle')
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    menuClick (item) {
      //
    }
  }
})
</script>
