export default [
  [
    {
      to: '/',
      icon: 'dashboard',
      label: 'Dashboard'
    },
    {
      to: '/supplier-matching',
      label: 'Supplier Matching',
      icon: 'compare'
    },
    {
      to: '/statement-summary',
      label: 'Statement Summary',
      icon: 'summarize'
    }
  ]
]
