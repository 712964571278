export default [
  [
    {
      to: '/settings/account-details',
      icon: 'account_circle',
      label: 'Account Details'
    },
    {
      to: '/settings/my-team',
      icon: 'group',
      label: 'My Team'
    },
    {
      to: '/settings/suppliers',
      label: 'Suppliers',
      icon: 'shelves'
    },
    {
      to: '/settings/categories',
      label: 'Categories',
      icon: 'category'
    },
    {
      to: '/settings/reconciliation-rules',
      label: 'Reconciliation Rules',
      icon: 'checklist_rtl'
    }
  ]
]
