<template>
  <div id="app">
    <nav-bar v-if="showHeaderNav" />
    <aside-menu
      v-if="showHeaderNav"
      :menu="navConfig"
      :nav-state="navState"
      @changeNavView="changeNavView"
    />
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import menu from '@/menu.js'
import UserSettings from '@/user-settings.config.js'
// import AdminSettings from '@/admin-settings.config.js'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'AppComponent',
  components: {
    AsideMenu,
    NavBar
  },
  data () {
    return {
      navConfig: menu,
      navState: 'menu',
      UserSettings,
      // AdminSettings,
      showHeaderNav: false
    }
  },
  computed: {
    ...mapState(['isAsideVisible', 'isAdmin'])
  },
  watch: {
    $route: function () {
      if (this.$route.path === '/login') {
        this.showHeaderNav = false
      } else {
        this.showHeaderNav = true
      }
      if (this.$route.path.includes('settings')) {
        // if (this.isAdmin) {
        //   this.navConfig = AdminSettings
        // } else {
        //   this.navConfig = UserSettings
        // }
        this.navConfig = UserSettings
        this.navState = 'settings'
      } else {
        this.navConfig = menu
        this.navState = 'menu'
      }
    }
  },
  methods: {
    asideDesktopOnlyToggle () {
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    changeNavView () {
      if (this.navState === 'menu') {
        // if (this.isAdmin) {
        //   this.$router.push('/settings/my-team')
        // } else {
        //   this.$router.push('/settings/account-details')
        // }
        this.$router.push('/settings/account-details')
      } else {
        this.$router.push('/')
      }
    }
  }
})
</script>
