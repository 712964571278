import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex)

const sessionPersist = new VuexPersist({
  key: 'sessionPersist',
  storage: window.sessionStorage,
  reducer: state => ({
    staffIDs: state.staffIDs,
    monthIDs: state.monthIDs,
    periodStatusIds: state.periodStatusIds,
    clientPartnerIDs: state.clientPartnerIDs,
    monthList: state.monthList,
    reconcileFilter: state.reconcileFilter,
    userName: state.userName,
    selectedMonth: state.selectedMonth,
    isAdmin: state.isAdmin,
    selectedMonthID: state.selectedMonthID
  })
})

const store = new Vuex.Store({
  plugins: [sessionPersist.plugin],
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    isAdmin: 0,
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,
    isNavExpandAble: false,

    /* Sample data (commonly used) */
    clients: [],

    /* reconcile task filter */

    monthIDs: [],
    clientPartnerIDs: [],
    periodStatusIds: [],
    staffIDs: [],
    monthList: [],
    clientPartnerList: [],
    periodStatusList: [],
    staffList: [],
    reconcileFilter: false,
    reconcileData: [],
    selectedMonthID: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },
    changeDate (state, payload) {
      state.selectedMonthID = payload
    },
    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.isAdmin) {
        state.isAdmin = payload.isAdmin
      }
    },

    updateReconcileTaskFilter (state, payload) {
      if (payload.staffIDs) {
        state.staffIDs = payload.staffIDs
      }
      if (payload.monthIDs) {
        state.monthIDs = payload.monthIDs
      }
      if (payload.periodStatusIds) {
        state.periodStatusIds = payload.periodStatusIds
      }
      if (payload.clientPartnerIDs) {
        state.clientPartnerIDs = payload.clientPartnerIDs
      }
      if (payload.monthList) {
        state.monthList = payload.monthList
      }
      if (payload.clientPartnerList) {
        state.clientPartnerList = payload.clientPartnerList
      }
      if (payload.periodStatusList) {
        state.periodStatusList = payload.periodStatusList
      }
      if (payload.staffList) {
        state.staffList = payload.staffList
      }
      state.reconcileFilter = true
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload
    },

    /*  update reconcile Data */
    updateReconcileData (state, payload) {
      state.reconcileData = payload
    }
  },
  actions: {
    asideDesktopOnlyToggle (store, payload = null) {
      let method

      switch (payload) {
        case true:
          method = 'add'
          break
        case false:
          method = 'remove'
          break
        default:
          method = 'toggle'
      }
      document.documentElement.classList[method](
        'has-aside-desktop-only-visible'
      )
    },
    toggleFullPage ({ commit }, payload) {
      commit('fullPage', payload)

      document.documentElement.classList[!payload ? 'add' : 'remove'](
        'has-aside-left',
        'has-navbar-fixed-top'
      )
    }
  }
})

export default store

export function useStore () {
  return store
}
